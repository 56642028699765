import React, { useEffect } from "react";
import { makeStyles, fade } from "@material-ui/core/styles";
import { withStyles } from '@material-ui/core/styles';
import ColumnContainer from './ColumnContainer'

import { Twemoji } from 'react-emoji-render';
import { Virtuoso } from "react-virtuoso";
import CssBaseline from '@material-ui/core/CssBaseline';
import Switch from '@material-ui/core/Switch';
import { Hidden} from "@material-ui/core";





import {
    fieldValue,
    myFirebase,
    twitterProvider,
    googleProvider,
    db,
    Timestamp
  } from "../firebase/firebase";

  import {
    Dialog,
    Typography,
    List,
    IconButton,
    Grid,
    Button,
    Divider,
    Input,
    ListItem,
    ListItemAvatar,
    Avatar,
    ListItemText
  } from "@material-ui/core";

  import ReactTimeAgo from 'react-time-ago'

  import TimeAgo from 'javascript-time-ago'

  import en from 'javascript-time-ago/locale/en'
  import ru from 'javascript-time-ago/locale/ru'

TimeAgo.addDefaultLocale(en)
TimeAgo.addLocale(ru)


  const useStyles = makeStyles((theme) => ({
    root: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: "white",
    },
    drawer: {
      width: '100px',
      flexShrink: 0,
    },
    drawerPaper: {
      width: '100px',
    },
    search: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: fade(theme.palette.common.white, 0.15),
      '&:hover': {
        backgroundColor: fade(theme.palette.common.white, 0.25),
      },
      marginRight: theme.spacing(2),
      marginLeft: 0,
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: 'auto',
      },
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    inputRoot: {
      color: 'inherit',
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '20ch',
      },
    },
    btn: {
      backgroundColor: "#e9e9e9",
      color: "black",
      "&&:hover": {
        backgroundColor: "#ffc700",
        color: "black",
      },
    },
    subBtn: {
      backgroundColor: "#ffc700",
      color: "black",
      "&&:hover": {
        backgroundColor: "#f7c600",
        color: "black",
      },
    },
    gridList: {
      width: "80%",
      height: "100vh",
    },
    link: {
      color: "grey",
      "&&:hover": {
        textDecoration: "none",
        textDecorationColor: "transparent",
      },
    },
    hoverLight: {
        backgroundColor: '#fafafa',
      "&&:hover": {
        backgroundColor: "#f8f8f8",
      },
    },
    input: {
      "&&&:before": {
        borderBottom: "none",
      },
      "&&:after": {
        borderBottom: "none",
      },
      fontFamily: "Open Sans",
      fontSize: 18,
    },
    nav: {
      backgroundColor: "#fafafa",
      "&&:disabled": {
        backgroundColor: "#fafafa",
      },
      "&&:hover": {
        backgroundColor: "#dfdfdf",
      },
    },
    noFocus: {
      "&&:hover": {
        backgroundColor: "#00000000",
        color: "orange",
      },
    },
    addButton: {
      borderRadius: 100,
      padding: "6px 12px 6px 12px",
      backgroundColor: "none",
      textTransform: "none",
      fontWeight: 800,
      fontFamily: "Open Sans",
      marginRight: 8,
      "&&:hover": {
        backgroundColor: "#fafafa",
      },
    },
    borderGrid: {
      [theme.breakpoints.down("xs")]: {
        border: "none",
      },
      border: "solid 1pt #E9E9E9",
      borderTop: 'none'
    },
    filterButton: {
      textTransform: "none",
      marginRight: 8,
      fontFamily: "Open Sans",
      padding: "6px 12px 6px 12px",
      fontWeight: 900,
      borderRadius: 100,
      color: "black",
      backgroundColor: "transparent",
      "&&:hover": {
        color: "black",
        backgroundColor: "#fafafa",
      },
    },
    signInButtonTwitter: {
      textTransform: "none",
      backgroundColor: "#1DA1F2",
      color: "white",
      fontWeight: 900,
      fontFamily: "Open Sans",
      borderRadius: 100,
      marginBottom: 16,
      padding: "4px 16px",
      "&&:hover": {
        color: "white",
        backgroundColor: "#188fd9",
      },
    },
    signInButtonGoogle: {
      textTransform: "none",
      backgroundColor: "#f6f6f6",
      color: "black",
      fontWeight: 900,
      fontFamily: "Open Sans",
      borderRadius: 100,
      padding: "4px 16px",
      "&&:hover": {
        color: "black",
        backgroundColor: "#f3f3f3",
      },
    },
    input : {
      backgroundColor: '#fafafa',
      "&&:hover": {
        backgroundColor: "#f7f7f7",
      },
      "&&&:before": {
        borderBottom: "none",
      },
      "&&:after": {
        borderBottom: "none",
      },
    }
  }));

  const AntSwitch = withStyles((theme) => ({
    root: {
      width: 28,
      height: 16,
      padding: 0,
      display: 'flex',
    },
    switchBase: {
      padding: 2,
      color: theme.palette.grey[500],
      '&$checked': {
        transform: 'translateX(12px)',
        color: theme.palette.common.white,
        '& + $track': {
          opacity: 1,
          backgroundColor: theme.palette.primary.main,
          borderColor: theme.palette.primary.main,
        },
      },
    },
    thumb: {
      width: 12,
      height: 12,
      boxShadow: 'none',
    },
    track: {
      border: `1px solid ${theme.palette.grey[500]}`,
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: theme.palette.common.white,
    },
    checked: {},
  }))(Switch);

  const signInUserTwitter = () => {
    myFirebase.auth().signInWithRedirect(twitterProvider);
  };
  
  const signInUserGoogle = () => {
    myFirebase.auth().signInWithRedirect(googleProvider);
  };



const Home = () => {
    const classes = useStyles();

    const [user, setUser] = React.useState(null);
    const [lovers, setLovers] = React.useState([]);
    const [isLoggedIn, setLoggedIn] = React.useState(null);

    const spreadTheLove = () => {
        if(user != null) {
            db.collection("lovers")
            .add({
              name: user['displayName'],
              datetime: Timestamp.fromDate(new Date()),
              img: user['providerData'][0]['photoURL'].replace('normal', '400x400'),
              twitter: ['ProductHunt']
            })
        } else {
            db.collection("lovers")
            .add({
              name: "Anonymous",
              datetime: Timestamp.fromDate(new Date()),
              img: 'https://firebasestorage.googleapis.com/v0/b/spread-the-love-2021.appspot.com/o/SPL.png?alt=media&token=058e7b8f-02b3-4b02-a329-fef731205508',
              twitter: ['ProductHunt']
            })
        }
      };

    useEffect(() => {

        myFirebase
        .auth()
        .getRedirectResult()
        .then((result) => {
            setUser(result.user);
            setLoggedIn(true);
        })
        .catch((error) => {
            console.log(error);
        });

        myFirebase.auth().onAuthStateChanged((user) => {
        if (user) {
            setUser(user);
            console.log(user)
            setLoggedIn(true);
        } else {
            setUser(null);
            setLoggedIn(false);
        }
    });
    }, [])


    useEffect(() => {
        db.collection("lovers")
            .orderBy("datetime", "desc")
            .limit(100)
            .onSnapshot(snapshot => {
                let lovers = []
                snapshot.forEach(doc => {
                    console.log(doc.data())
                    lovers.push(doc.data())
                })
                setLovers(lovers)
        })
    }, [])



    const Lover = (index) => {
        let lover = lovers[index]
        var checkDate = new Date() - lover['datetime'].toDate() < 5000

        return (
            <a
            style={{ marginLeft: 0, textDecoration: 'none'}}
            className={classes.link}
            target="_blank"
            rel="noopener"
            href={"http://twitter.com/" + lover["twitter"]}
          >
            <ListItem
       
        style={{padding: '0px 18px 8px 18px', borderRadius: 20, border: checkDate ? '1pt solid #eeeeee' : '1pt solid #eeeeee',  marginTop: 16, width: '100%'}}
        className={classes.hoverLight}
        disableGutters
        alignItems="flex-start"
      >
       
        <ListItemAvatar>
            <Avatar
              alt={lover["name"]}
              style={{
                marginRight: 16,
                border: "solid 3pt #f7f7f7",
                backgroundColor: "#fafafa",
                width: 60,
                height: 60,
                color: "grey"
              }}
              src={
               lover['img']
              }
            />
        </ListItemAvatar>

        <ListItemText
          primary={
            <>
           
        
              <div style={{marginTop: 10}}>
                <Typography
                  component={"span"}
                  style={{
                    fontWeight: 900,
                    marginRight: 6,
                    wordBreak: "break-word",
                    color: "black",
                    fontFamily: "Open Sans",
                    fontSize: 16,
                    marginTop: 8,
                  }}
                >
                  {lover["name"]}
                </Typography>

                <Typography
                  component="span"
                  style={{
                    color: "#aaaaaa",
                    marginTop: 4,
                    fontWeight: 400,
                    fontFamily: "Open Sans",
                    fontSize: 14,
                  }}
                >
                  ·{" "}
                  <ReactTimeAgo date={lover['datetime'].toDate()} timeStyle="twitter"/> 
                </Typography>

            </div>

            <Typography
                  component={"span"}
                  style={{
                    fontWeight: 400,
                    marginRight: 6,
                    wordBreak: "break-word",
                    color: "grey",
                    fontFamily: "Open Sans",
                    fontSize: 18,
                    marginTop: 8,
                  }}
                >
                           <Twemoji svg text="❤️🌍🤗" />
                </Typography>

            </>
          }
        />
      </ListItem>
      </a>
        );
    };

    return ( 
        <div className={classes.root}>
       <ColumnContainer
       nav = {
        <div>
        <CssBaseline />
        <Typography style={{fontWeight: 900, margin: 16, fontFamily: 'Open Sans', marginBottom: 0, fontSize: 48, lineHeight: 1.2}}>
           SHOW THE   <Twemoji svg text="🌎" /> SOME   <Twemoji svg text="❤️" />
        </Typography>
        <Hidden smDown>
        <Typography style={{fontWeight: 400, margin: 16, fontFamily: 'Open Sans', marginBottom: 0, marginTop: 8, fontSize: 18, lineHeight: 1.2, width: '75%'}}>
            Press the button to spread the love. Everyone who pressed the button will show up on the list to the right.
        </Typography>
        </Hidden>
        <Hidden mdUp>
        <Typography style={{fontWeight: 400, margin: 16, fontFamily: 'Open Sans', marginBottom: 0, marginTop: 8, fontSize: 18, lineHeight: 1.2, width: '75%'}}>
            Press the button to spread the love. Everyone who pressed the button will show up on the list below.
        </Typography>
        </Hidden>
        <Button  onClick={()=> {spreadTheLove()}} style={{fontFamily: 'Open Sans', fontSize: 18, textTransform: 'none', color: '#ea4335', backgroundColor: 'rgba(255, 157, 151, 0.15)', fontWeight: 900, borderRadius: 4, height: 45, width: 250, margin: 16, marginBottom: 8, marginTop: 24}}>
          <i style={{fontSize: 24, marginRight: 14, color: '#ea4335'}} className="ri-heart-3-fill"></i>SPREAD THE LOVE
        </Button> 

        
        <>
        {isLoggedIn ? 
        <>
        <div/>
        <Typography style={{fontWeight: 400, margin: 16, fontFamily: 'Open Sans', marginBottom: 0, marginTop: 16}}>
            Sign out
        </Typography>
         <Button onClick={()=> { myFirebase.auth().signOut()
          setLoggedIn(false)
          setUser(null)}} style={{fontFamlity: 'Open Sans', textTransform: 'none', color: '#aaaaaa', backgroundColor: '#fafafa', fontWeight: 400, borderRadius: 4, height: 45, width: 215, margin: 16, marginBottom: 8}}>
            <i style={{fontSize: 20, marginRight: 14}}><Twemoji svg text="✌" /></i>Be anonymous again
        </Button>
        </>
        :
        <>
        <Typography style={{fontWeight: 400, margin: 16, fontFamily: 'Open Sans', marginBottom: 0, marginTop: 16}}>
            Sign in to add your name<br/>and photo
        </Typography>
        <Button onClick={()=> {signInUserTwitter()}}  className={classes.hoverLight}  style={{fontFamily: 'Open Sans',textTransform: 'none', color: '#aaaaaa', fontWeight: 400, borderRadius: 4, height: 45, width: 215, margin: 16, marginBottom: 8, marginTop: 12}}>
            <i style={{color: '#0aa9f4', fontSize: 20, marginRight: 14}}className="ri-twitter-fill"></i>Continue with Twitter
        </Button>
        <div/>
        <Button  onClick={()=> {signInUserGoogle()}} className={classes.hoverLight} style={{fontFamily: 'Open Sans',textTransform: 'none', color: '#aaaaaa', fontWeight: 400, borderRadius: 4, height: 45, width: 215, margin: 16, marginTop: 0}}>
            <svg style={{marginRight: 16}} width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><path d="M17.64 9.205c0-.639-.057-1.252-.164-1.841H9v3.481h4.844a4.14 4.14 0 0 1-1.796 2.716v2.259h2.908c1.702-1.567 2.684-3.875 2.684-6.615z" fill="#4285F4" fill-rule="nonzero"></path><path d="M9 18c2.43 0 4.467-.806 5.956-2.18l-2.908-2.259c-.806.54-1.837.86-3.048.86-2.344 0-4.328-1.584-5.036-3.711H.957v2.332A8.997 8.997 0 0 0 9 18z" fill="#34A853" fill-rule="nonzero"></path><path d="M3.964 10.71A5.41 5.41 0 0 1 3.682 9c0-.593.102-1.17.282-1.71V4.958H.957A8.996 8.996 0 0 0 0 9c0 1.452.348 2.827.957 4.042l3.007-2.332z" fill="#FBBC05" fill-rule="nonzero"></path><path d="M9 3.58c1.321 0 2.508.454 3.44 1.345l2.582-2.58C13.463.891 11.426 0 9 0A8.997 8.997 0 0 0 .957 4.958L3.964 7.29C4.672 5.163 6.656 3.58 9 3.58z" fill="#EA4335" fill-rule="nonzero"></path><path d="M0 0h18v18H0z"></path></g></svg>Continue with Google
        </Button>
        </>
       
      
       }
          <div style={{marginTop: 16, marginLeft: 14}}>
       <a
                style={{ color: "grey", fontFamily: 'Open Sans', textDecoration: 'none'}}
                target="_blank"
                rel="noopener"
                href="https://twitter.com/dijkstradev"
              >
                <div style={{borderRadius: 4, padding: "4px", display: 'inline-block'}}>
                Made with <Twemoji style={{marginRight: 1}} svg text="❤️" /> by
            
                <b>  @dijkstradev</b>
             
              </div>
              
              </a>
              </div>
         </>
         <Hidden mdUp>

                
        <Virtuoso
        totalCount={lovers.length}
        overscan={200}
        item={Lover}
        style={{ padding: 0, height: "100vh", width: "100%" }}

        />
        </Hidden>
    </div>
       

      
       }
        main = {
            <>
            <CssBaseline />
            <Hidden smDown>

           
            <Virtuoso
            totalCount={lovers.length}
            overscan={200}
            item={Lover}
            style={{ padding: 0, height: "100vh", width: "100%"}}
        
          />
           </Hidden>
           </>
        }

       
       />
       </div>
     );
}
 
export default Home;