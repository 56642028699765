import firebase from "firebase/app";
import 'firebase/auth';
import 'firebase/firestore'

const firebaseConfig = {
  apiKey: "AIzaSyBFMpQQqJ_WL4vcIrACB5HO1htYQ7ewW1Q",
  authDomain: "spread-the-love-2021.firebaseapp.com",
  projectId: "spread-the-love-2021",
  storageBucket: "spread-the-love-2021.appspot.com",
  messagingSenderId: "244089548252",
  appId: "1:244089548252:web:444dd9f91409d097e0f319"
};


export const myFirebase = firebase.initializeApp(firebaseConfig);

export var twitterProvider = new firebase.auth.TwitterAuthProvider();
export var googleProvider = new firebase.auth.GoogleAuthProvider();

export const db = myFirebase.firestore();

export const Timestamp = firebase.firestore.Timestamp

export const fieldValue = firebase.firestore.FieldValue;

export const auth =  firebase.auth();
